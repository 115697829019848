import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import SEO from '../components/seo';
import '../components/layout.css';
import { Page, PageTitle, PageSubtitle } from '../styles';

var BlogPage = function BlogPage() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SEO, {
    title: "Projects"
  }), ___EmotionJSX(Page, null, ___EmotionJSX(PageTitle, null, "My Projects"), ___EmotionJSX(PageSubtitle, null, "Coming Soon")));
};

export default BlogPage;